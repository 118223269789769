import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Threadwalkers`}</strong>{`, or `}<em parentName="p">{`Threadhoppers`}</em>{`, are individuals with the ability to, while dreaming, hop amongst `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Threads`}</a>{` and enter others dreams. This ability is unrelated to a talent with `}<a parentName="p" {...{
        "href": "/Conviction",
        "title": "Conviction"
      }}>{`Convictions`}</a>{` or `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Luminous Magic`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      